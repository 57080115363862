<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-4 pb-0"
      >
        <PageHeader :title="title" class="pt-5" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0 mt-2 mb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="pr-4">
                  <!-- Empresa -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      v-model="empresaSelected"
                      :items="empresaItems"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      :rules="rules.required"
                      dense
                      autocomplete="off"
                      label="Entidad facturante"
                      hint="Nombre"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Agente de facturacion -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      ref="agentes-autocomplete"
                      v-model="agenteFacturacionSelected"
                      :items="agentesFacturacionItems"
                      :search-input.sync="agentesFacturaAutocompleteInput"
                      item-text="value1"
                      item-value="id"
                      :filter="customFilterAgentes"
                      :rules="rules.required"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Agente de facturación"
                      hint="Nombre, CUIT o código"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value1
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Código: {{ item.value2 }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="agentesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Convenios -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      ref="convenios-autocomplete"
                      class="p-0"
                      v-model="convenioSelected"
                      :items="itemsConvenio"
                      @change="loadSubconvenios()"
                      label="Convenio"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- fecha desde -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Monedas -->
                  <v-col
                    cols="3"
                    class="py-0"
                    md="3"
                    v-if="this.paramGralMultimoneda"
                  >
                    <v-autocomplete
                      v-model="monedaSelected"
                      :items="monedaItems"
                      :rules="rules.required"
                      label="Moneda de consulta"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha Cotiza -->
                  <v-col
                    cols="6"
                    md="3"
                    class="py-0"
                    v-if="this.paramGralMultimoneda"
                  >
                    <v-menu
                      ref="fecha-cotiza"
                      v-model="menuFechaCotiza"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaCotizaSelected"
                          label="Fecha cotización"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaCotiza = parseDateToIso(fechaCotizaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaCotiza"
                        no-title
                        @change="fechaCotizaSelected = formatDate(fechaCotiza)"
                        @input="menuFechaCotiza = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- switch 1 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="facturaConVenc"
                      label="Solo factura con vencimiento al:"
                    ></v-switch>
                  </v-col>
                  <!-- Fecha de vencimiento -->
                  <v-col
                    cols="6"
                    md="3"
                    class="py-0 ml-n10"
                    v-if="this.facturaConVenc"
                  >
                    <v-menu
                      ref="fecha-vencimiento"
                      v-model="menuFechaVencimiento"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVencimientoSelected"
                          label="Fecha de vencimiento"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaVencimiento = parseDateToIso(
                              fechaVencimientoSelected
                            )
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVencimiento"
                        no-title
                        @change="
                          fechaVencimientoSelected =
                            formatDate(fechaVencimiento)
                        "
                        @input="menuFechaVencimiento = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- switch 2 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="incluirCompRelacion"
                      label="Incluir comprobantes relacionados"
                    ></v-switch>
                  </v-col>
                  <!-- switch 3 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="soloComprobanteConSaldo"
                      label="Solo comprobante con saldo"
                    ></v-switch>
                  </v-col>
                  <!-- SubConvenios -->
                  <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                    v-if="this.paramGralSubConvenio"
                  >
                    <v-autocomplete
                      ref="subConvenios-autocomplete"
                      class="p-0"
                      v-model="subConvenioSelected"
                      :items="itemsSubConvenio"
                      :disabled="this.haveConvenioSelected"
                      label="Subconvenio"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    type="submit"
                    :disabled="!isFormValid"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
            <p
              :class="
                showFilters
                  ? 'invalidConfigText mb-n1'
                  : 'invalidConfigText mb-n1 ml-2'
              "
              v-if="this.haveCotiza && this.paramGralMultimoneda"
            >
              Existen comprobantes sin cotización
            </p>
          </v-container>
        </v-card>
        <!-- tablas de informacion -->
        <v-card>
          <!-- tabla de comprobantes -->
          <v-data-table
            :headers="headersCompGral"
            :items="itemsComprobantes"
            height="100%"
            fixed-header
            class="elevation-1"
            :loading="isLoadingTable"
            item-key="pfCompId"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-toolbar-title class="primary--text"
                  >Comprobantes</v-toolbar-title
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobante(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="toggleComprobantesCancelatorios(item)"
                  >
                    {{ comprobanteCancelatorioIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobantes cancelatorios</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-card class="mt-4">
          <!-- tabla de comprobantes sin relacionar -->
          <v-data-table
            :headers="headersCompSinRelacionar"
            :items="itemsComprobantesSinRelacionar"
            height="100%"
            fixed-header
            class="elevation-1"
            :loading="isLoadingTable"
            item-key="pfCompId"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-toolbar-title class="primary--text"
                  >Comprobantes sin relacionar</v-toolbar-title
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobanteSinRela(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- saldos totales -->
    <v-row class="mt-3">
      <v-col cols="12" md="3" class="d-flex py-0">
        <v-text-field
          v-model.trim="saldoInicial"
          :label="saldoInicialView"
          outlined
          persistent-hint
          disabled
          filled
          readonly
          dense
          class="right-input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-flex py-0">
        <v-text-field
          v-model.trim="saldoFinal"
          :label="saldoFinalView"
          outlined
          persistent-hint
          disabled
          filled
          readonly
          dense
          class="right-input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="4" align="right" class="d-flex py-0">
        <v-tooltip top max-width="40%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="fontsize"
              v-bind="attrs"
              outlined
              :disabled="itemsComprobantes.length == 0"
              @click="exportExcelModelo"
            >
              Exportar comprobantes
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- Ver comprobantes cancelatorios -->
    <v-dialog
      v-if="modalComprobanteCancelatorio"
      v-model="modalComprobanteCancelatorio"
      max-width="60%"
    >
      <ComprobantesCancelatoriosFacturaOS
        :pfCompId="pfCompId"
        :monedaSelected="this.filtrosGuardados.monedaId"
        :fechaCotiza="this.filtrosGuardados.fechaCotiza"
        :comprobantesCancelatorios="itemsComprobantesCancel"
        @closeModalComprobantesCancelatorios="
          closeModalComprobantesCancelatorios
        "
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import Ayuda from "@/components/shared/Ayuda.vue";
import ComprobantesCancelatoriosFacturaOS from "@/components/modules/facturacionOS/ComprobantesCancelatoriosFacturaOS.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "CuentaCorrienteFacturacionOS",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    ComprobantesCancelatoriosFacturaOS,
  },
  data: () => ({
    title: "Cuenta corriente de agente de facturación",
    filtersApplyed: [],
    rules: rules,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    seeIcon: enums.icons.SEE,
    comprobanteCancelatorioIcon: enums.icons.FILE_CANCEL,
    optionCode: enums.webSiteOptions.CUENTA_CORRIENTE_FACTURACIONOS,
    showHelp: false,
    showExpand: false,
    showFilters: true,
    isFormValid: false,
    monedaObject: null,
    allowedActions: null,
    filtersApplied: [],
    filtrosGuardados: {},
    empresaSelected: null,
    agenteFacturacionSelected: null,
    convenioSelected: null,
    itemsConvenio: [],
    monedaSelected: null,
    monedaItems: [],
    empresaItems: [],
    agentesFacturacionItems: [],
    subConvenioSelected: null,
    itemsSubConvenio: [],
    menuFechaDesde: false,
    menuFechaHasta: false,
    menuFechaCotiza: false,
    menuFechaVencimiento: false,
    fechaDesdeSelected: null,
    fechaHastaSelected: null,
    fechaCotizaSelected: null,
    fechaVencimientoSelected: null,
    fechaDesde: null,
    fechaHasta: null,
    fechaCotiza: null,
    fechaVencimiento: null,
    pfCompId: null,
    soloComprobanteConSaldo: false,
    incluirCompRelacion: false,
    facturaConVenc: false,
    paramGralMultimoneda: false,
    paramGralSubConvenio: false,
    agentesFacturaAutocompleteInput: null,
    agentesLoading: false,
    setInitialParameter: true,
    isLoadingTable: false,
    setInitialParameter: true,
    modalComprobanteCancelatorio: false,
    haveConvenioSelected: true,
    haveCotiza: false,
    saldoInicial: 0,
    saldoInicialView: "Saldo inicial",
    saldoFinalView: "Saldo final",
    saldoFinal: 0,
    itemsComprobantesCancel: [],
    itemsComprobantes: [],
    headersCompGral: [
      {
        text: "Número",
        sortable: false,
        value: "nroComprobante",
      },
      {
        text: "Fecha",
        sortable: false,
        value: "pfFecha",
      },
      {
        text: "Fecha de vencimiento",
        sortable: false,
        value: "pfFechaVto",
      },
      {
        text: "Total",
        sortable: false,
        align: "end",
        value: "pfTotal",
      },
      {
        text: "Cancelado",
        sortable: false,
        align: "end",
        value: "cancelado",
      },
      {
        text: "Saldo",
        sortable: false,
        align: "end",
        value: "deuda",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
    ],
    itemsComprobantesSinRelacionar: [],
    headersCompSinRelacionar: [
      {
        text: "Número",
        sortable: false,
        value: "nroComprobante",
      },
      {
        text: "Fecha",
        sortable: false,
        value: "pfFecha",
      },
      {
        text: "Importe sin relacionar",
        sortable: false,
        align: "end",
        value: "pfTotal",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
    ],
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setSelects();
  },
  watch: {
    agentesFacturaAutocompleteInput(val) {
      const value = val.trimLeft();
      if (value) {
        this.getAgentesFacturaFilter();
      }
    },
  },
  async mounted() {
    if (this.$store.state.filtersFacturacion != null) {
      this.empresaSelected = this.$store.state.filtersFacturacion.empresa;
      const responseArray = await this.fetchAgentesFacturacionByQuery({
        input: this.$store.state.filtersFacturacion.agenteFactura.value1,
      });
      this.agentesFacturacionItems = responseArray;
      this.agenteFacturacionSelected = this.agentesFacturacionItems[0];
      this.fechaDesde = this.$store.state.filtersFacturacion.fechaDesde;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = this.$store.state.filtersFacturacion.fechaHasta;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.fechaCotiza = this.$store.state.filtersFacturacion.fechaCotiza;
      this.fechaCotizaSelected = this.formatDate(this.fechaCotiza);
      this.fechaVencimiento =
        this.$store.state.filtersFacturacion.fechaVencimiento;
      this.fechaVencimientoSelected = this.fechaVencimiento
        ? this.formatDate(this.fechaVencimiento)
        : null;
      this.soloComprobanteConSaldo =
        this.$store.state.filtersFacturacion.compConSaldo;
      this.convenioSelected = this.$store.state.filtersFacturacion.osId;
      if (this.convenioSelected) this.haveConvenioSelected = false;
      this.subConvenioSelected =
        this.$store.state.filtersFacturacion.subConvenio;
      this.loadSubconvenios();
      this.facturaConVenc = this.$store.state.filtersFacturacion.soloFactura;
      this.incluirCompRelacion =
        this.$store.state.filtersFacturacion.incluirCompRelacionados;
      this.monedaSelected = this.$store.state.filtersFacturacion.monedaId;
      this.applyFilters();
    }
  },
  methods: {
    ...mapActions({
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParametroGralFechaDesde: "proveedores/getParametroGralFechaDesde",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getParametrosGralesBenef: "afiliaciones/getParametrosGralesBenef",
      getSubconveniosByConvenio: "afiliaciones/getSubconveniosByConvenio",
      fetchAgentesFacturacionByQuery:
        "facturacionOS/fetchAgentesFacturacionByQuery",
      getConvenios: "afiliaciones/getConvenios",
      fetchComprobantesCtaCteFacturacionOS:
        "facturacionOS/fetchComprobantesCtaCteFacturacionOS",
    }),
    async setSelects() {
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresaItems = entidadesFacturantes;
      const convenios = await this.getConvenios();
      this.itemsConvenio = convenios;
      if (this.empresaItems.length == 1) {
        this.empresaSelected = this.empresaItems[0];
      }
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      this.monedaItems = monedasResponse;
      if (this.setInitialParameter) {
        //solo seteo para pantalla inicial el tipo fecha, la moneda vigente y fecha desde/hasta
        const monedaVig = monedasResponse.find((x) => x.bool == true);
        this.monedaSelected = monedaVig;
        this.setFechaInicial();
      }
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      const responseParamSubConv = await this.getParametrosGralesBenef();
      this.paramGralSubConvenio = responseParamSubConv.subConvenio;
    },
    async setFechaInicial() {
      // fecha de hoy para seteo inicial de fecha hasta
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      // seteo inicial de fecha desde la base de datos
      const response = await this.getParametroGralFechaDesde();
      const FechaBaseDatos = response[0].fecDesdePorDefecto;
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.fechaCotiza = `${año}-${mes}-${dia}`;
      this.fechaCotizaSelected = this.formatDate(this.fechaCotiza);
      this.fechaDesde = this.parseDateToIso(FechaBaseDatos);
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
    },
    async applyFilters() {
      this.isLoadingTable = true;
      this.haveCotiza = false;
      this.$store.state.filtersFacturacion = null;
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      const responseParamSubConv = await this.getParametrosGralesBenef();
      this.paramGralSubConvenio = responseParamSubConv.subConvenio;
      this.setInitialParameter = false;
      this.customizeFiltersApplied();
      const filters = {
        entFac: this.empresaSelected.id,
        ageFactId: this.agenteFacturacionSelected.id,
        osId: this.convenioSelected?.id,
        convId: this.subConvenioSelected ? this.subConvenioSelected.id : 0,
        deta: 0,
        saldo: this.soloComprobanteConSaldo,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        fecVenc: this.facturaConVenc ? this.fechaVencimiento : null,
        incluirRelacionados: this.incluirCompRelacion,
        monedaId:
          this.monedaSelected && this.paramGralMultimoneda
            ? this.monedaSelected.id
            : -1,
        fechaCotizacion: this.paramGralMultimoneda ? this.fechaCotiza : null,
      };
      this.filtrosGuardados = {
        empresa: this.empresaSelected,
        agenteFactura: this.agenteFacturacionSelected,
        subConvenio: this.subConvenioSelected,
        osId: this.convenioSelected,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        fechaVencimiento: this.fechaVencimiento,
        compConSaldo: this.soloComprobanteConSaldo,
        soloFactura: this.facturaConVenc,
        incluirCompRelacionados: this.incluirCompRelacion,
        monedaId:
          this.monedaSelected && this.paramGralMultimoneda
            ? this.monedaSelected
            : null,
        fechaCotiza: this.paramGralMultimoneda ? this.fechaCotiza : null,
      };
      try {
        const response = await this.fetchComprobantesCtaCteFacturacionOS(filters);
        this.itemsComprobantes = response.filter(
          (x) => x.agru == "A" && x.pf != 0
        );
        this.itemsComprobantesCancel = response.filter(
          (x) => x.agru == "B" && x.pf != 0
        );
        this.itemsComprobantesSinRelacionar = response.filter(
          (x) => x.agru == "B" && x.pf == 0
        );
        this.saldoInicialView = `Saldo inicial al: ${this.formatDate(
          this.fechaDesde
        )}`;
        this.saldoFinalView = `Saldo final al: ${this.formatDate(
          this.fechaHasta
        )}`;
        if (this.itemsComprobantes.length != 0) {
          this.saldoInicial = this.itemsComprobantes[0]
            ? this.itemsComprobantes[0].saldoInicial
            : 0;
          this.saldoFinal = this.itemsComprobantes[0]
            ? this.itemsComprobantes[0].saldoFinal
            : 0;
        } else {
          this.saldoInicial = this.itemsComprobantesSinRelacionar[0]
            ? this.itemsComprobantesSinRelacionar[0].saldoInicial
            : 0;
          this.saldoFinal = this.itemsComprobantesSinRelacionar[0]
            ? this.itemsComprobantesSinRelacionar[0].saldoFinal
            : 0;
        }
        const anyObjectWithCotiza = response.some((x) => x.haveCotiza == 0);
        if (anyObjectWithCotiza) this.haveCotiza = true;
        this.customizeFiltersApplied();
        this.showFilters = false;
        this.isLoadingTable = false;
      } catch {
        this.isLoadingTable = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplyed = [];
      if (this.empresaSelected) {
        this.filtersApplyed.splice(0, 1, {
          key: "empresaSelected",
          label: "Entidad facturante",
          model: this.empresaSelected.value,
        });
      }
      if (this.agenteFacturacionSelected) {
        this.filtersApplyed.splice(1, 1, {
          key: "agenteFacturacionSelected",
          label: "Agente de facturación",
          model: this.agenteFacturacionSelected.value1,
        });
      }
      if (this.convenioSelected) {
        this.filtersApplyed.splice(2, 1, {
          key: "convenioSelected",
          label: "Convenio",
          model: this.convenioSelected.value,
        });
      }
      if (this.fechaDesde) {
        this.filtersApplyed.splice(3, 1, {
          key: "fechaDesde",
          label: "Vigencia desde",
          model: this.formatDate(this.fechaDesde),
        });
      }
      if (this.fechaHasta) {
        this.filtersApplyed.splice(4, 1, {
          key: "fechaHasta",
          label: "Vigencia hasta",
          model: this.formatDate(this.fechaHasta),
        });
      }
      if (this.monedaSelected && this.paramGralMultimoneda) {
        this.filtersApplyed.splice(5, 1, {
          key: "monedaSelected",
          label: "Moneda de consulta",
          model: this.monedaSelected.value,
        });
      }
      if (this.fechaCotiza && this.paramGralMultimoneda) {
        this.filtersApplyed.splice(6, 1, {
          key: "fechaCotiza",
          label: "Fecha cotización",
          model: this.formatDate(this.fechaCotiza),
        });
      }
      if (this.soloComprobanteConSaldo || !this.soloComprobanteConSaldo) {
        this.filtersApplyed.splice(7, 1, {
          key: "soloComprobanteConSaldo",
          label: "Solo comprobante con saldo",
          model: this.soloComprobanteConSaldo ? "SI" : "NO",
        });
      }
      if (this.incluirCompRelacion || !this.incluirCompRelacion) {
        this.filtersApplyed.splice(8, 1, {
          key: "incluirCompRelacion",
          label: "Incluir comprobantes relacionados",
          model: this.incluirCompRelacion ? "SI" : "NO",
        });
      }
      if (this.facturaConVenc || !this.facturaConVenc) {
        this.filtersApplyed.splice(9, 1, {
          key: "facturaConVenc",
          label: this.facturaConVenc
            ? "Solo factura con vencimiento al"
            : "Solo factura con vencimiento",
          model: this.facturaConVenc
            ? this.formatDate(this.fechaVencimiento)
            : "NO",
        });
      }
      if (this.subConvenioSelected) {
        this.filtersApplyed.splice(10, 1, {
          key: "subConvenioSelected",
          label: "Subconvenio",
          model: this.subConvenioSelected.value,
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    async loadSubconvenios() {
      if (this.convenioSelected != null) {
        this.haveConvenioSelected = false;
        const response = await this.getSubconveniosByConvenio(this.convenioSelected.id);
        //saco del array el item que es sin dato
        const itemsSinDato = response.filter((x) => x.id != 0);
        this.itemsSubConvenio = itemsSinDato;
      } else {
        this.haveConvenioSelected = true;
        this.subConvenioSelected = null;
      }
    },
    getAgentesFacturaFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.agentesFacturacionItems?.some(
            (x) =>
              x.value2 == this.agentesFacturaAutocompleteInput ||
              x.value1 == this.agentesFacturaAutocompleteInput
          )
        ) {
          return;
        } else {
          this.agentesLoading = true;
          const response = await this.fetchAgentesFacturacionByQuery({
            input: this.agentesFacturaAutocompleteInput,
          });
          this.agentesFacturacionItems = response;
          this.agentesLoading = false;
        }
      }, 1000);
    },
    customFilterAgentes(item, queryText, itemText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    goToVerComprobante(item) {
      this.$router.push({
        name: "DetalleComprobanteFacturacionOS",
        params: {
          pfCompId: item.pfCompId,
          monedaToDetalle: this.filtrosGuardados.monedaId,
          fechaCotizaToDetalle: this.filtrosGuardados.fechaCotiza,
          goBackName: "CuentaCorrienteFacturacionOS"
        },
      });
      this.$store.state.filtersFacturacion = this.filtrosGuardados;
    },
    goToVerComprobanteSinRela(item) {
      this.$router.push({
        name: "DetalleComprobanteCancelaFacturacionOS",
        params: {
          pfCompId: item.pfCompId,
          monedaToDetalle: this.filtrosGuardados.monedaId,
          fechaCotizaToDetalle: this.filtrosGuardados.fechaCotiza,
          goBackName: "CuentaCorrienteFacturacionOS"
        },
      });
      this.$store.state.filtersFacturacion = this.filtrosGuardados;
    },
    toggleComprobantesCancelatorios(item) {
      this.modalComprobanteCancelatorio = true;
      this.pfCompId = item.pfCompId;
      this.$store.state.filtersFacturacion = this.filtrosGuardados;
    },
    closeModalComprobantesCancelatorios() {
      this.modalComprobanteCancelatorio = false;
    },
    exportExcelModelo() {
      let result = [];
      //armo el objeto para cada comp y lo voy pusheando a result
      this.itemsComprobantes?.forEach((x) => {
        const objetoComprobanteDeuda = {
          ["Entidad facturante"]: x.entFactNombre,
          ["Agente de facturación"]: x.ageFactNombre,
          ["Tipo de comprobante"]: x.tCompNombre,
          ["Número"]: x.nroComprobante,
          ["Fecha"]: x.pfFecha,
          ["Fecha de vencimiento"]: x.pfFechaVto,
          ["Total"]: x.pfTotal,
          ["Cancelado"]: x.cancelado,
          ["Saldo"]: x.deuda,
          ["Período"]: x.periodo,
          ["Detalle"]: "Comprobante",
        };
        // Verifica si parametro gral multimoneda esta activado para insertar ciertos datos
        if (this.paramGralMultimoneda) {
          objetoComprobanteDeuda["Moneda del comprobante"] = x.monedaNombre == "" ? "PESO" : x.monedaNombre.toUpperCase();
          objetoComprobanteDeuda["Moneda de consulta"] = this.filtrosGuardados.monedaId.value.toUpperCase();
          objetoComprobanteDeuda["Fecha cotización"] = this.formatDate(this.filtrosGuardados.fechaCotiza);
        }
        result.push(objetoComprobanteDeuda);
      });
      this.itemsComprobantesSinRelacionar?.forEach((x) => {
        const objetoComprobanteSinRela = {
          ["Entidad facturante"]: x.entFactNombre,
          ["Agente de facturación"]: x.ageFactNombre,
          ["Tipo de comprobante"]: x.tCompNombre,
          ["Número"]: x.nroComprobante,
          ["Fecha"]: x.pfFecha,
          ["Fecha de vencimiento"]: x.pfFechaVto,
          ["Total"]: "0.00",
          ["Cancelado"]: x.pfTotal,
          ["Saldo"]: "0.00",
          ["Período"]: x.periodo,
          ["Detalle"]: "Comprobante sin relacionar",
        };
        // Verifica si parametro gral multimoneda esta activado para insertar ciertos datos
        if (this.paramGralMultimoneda) {
          objetoComprobanteSinRela["Moneda del comprobante"] = x.monedaNombre == "" ? "PESO" : x.monedaNombre.toUpperCase();
          objetoComprobanteSinRela["Moneda de consulta"] = this.filtrosGuardados.monedaId.value.toUpperCase();
          objetoComprobanteSinRela["Fecha cotización"] = this.formatDate(this.filtrosGuardados.fechaCotiza);
        }
        result.push(objetoComprobanteSinRela);
      });
      this.itemsComprobantesCancel?.forEach((x) => {
        const objetoComprobanteCancela = {
          ["Entidad facturante"]: x.entFactNombre,
          ["Agente de facturación"]: x.ageFactNombre,
          ["Tipo de comprobante"]: x.tCompNombre,
          ["Número"]: x.nroComprobante,
          ["Fecha"]: x.pfFecha,
          ["Fecha de vencimiento"]: x.pfFechaVto,
          ["Total"]: "0.00",
          ["Cancelado"]: x.pfTotal,
          ["Saldo"]: "0.00",
          ["Período"]: x.periodo,
          ["Detalle"]: "Comprobante cancelatorio",
        };
        // Verifica si parametro gral multimoneda esta activado para insertar ciertos datos
        if (this.paramGralMultimoneda) {
          objetoComprobanteCancela["Moneda del comprobante"] = x.monedaNombre == "" ? "PESO" : x.monedaNombre.toUpperCase();
          objetoComprobanteCancela["Moneda de consulta"] = this.filtrosGuardados.monedaId.value.toUpperCase();
          objetoComprobanteCancela["Fecha cotización"] = this.formatDate(this.filtrosGuardados.fechaCotiza);
        }
        result.push(objetoComprobanteCancela);
      });
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Comprobantes de Facturación OS");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
.btnDisabled {
  color: #100f0f99;
}
.label {
  opacity: 0.7;
}
.invalidConfigText {
  color: red;
}
</style>